/* src/components/Footer/Footer.css */
.footer {
    background-color: black;
    color: #fff;
    text-align: center;
    margin-top: 10vh;
}

a {
    color: white;
    /*text-decoration: underline; !* Underline the link *!*/
}

a:hover {
    color: greenyellow; /* Change color on hover */
    text-decoration: none;
}

.privacyLink {
    color: white;
}

img.footerImage {
    object-fit: scale-down;
    max-width: 25px;
    padding-left: 5px;
    max-height: 25px;
}

.footerAppIcon {
    padding-right: 5px;
}

.footerLink {
    position: absolute;
    bottom: 0;
    left: 0;
}
