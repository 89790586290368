.loginFormControl {
    font-size: 14px;
    display: block;
    align-content: center;
    background: white;
    color: black;
    border: 2px solid black;
}

.loginForm {
    padding: 25px;
    padding-top: 20vh;
    padding-bottom: 18vh;
    margin-top: 3vh;
    min-width: 50%;
    min-height: 69vh; /* nice */
    display: inline-block;
}
