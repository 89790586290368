.liveEventRow {
    justify-content: center;
    background-color: #C7D0E7;
}

.liveEventViewerRow,
.liveEventViewItem {
    background-color: #C7D0E7;
}

.liveEventViewItem {
    outline: none;
    border: none;
    /* margin-left: 25vw; */
    font-size: 2.5vw;
}