img.linkedinImage {
    object-fit: scale-down;
    max-width: 25px;
}

img.aboutUsCoverImage {
    max-height: 250px;
}

h4 {
    text-align: center;
    margin: 20px;
    font-size: 2vw;
}

h2 {
    text-align: center;
    font-weight: bold;
    font-size: 3.5vw;
}

h4.mobile {
    text-align: center;
    margin: 20px;
    font-size: 5vw;
}

h2.mobile {
    text-align: center;
    font-weight: bold;
    font-size: 10vw;
}

h5 {
    text-align: center;
    font-weight: bold;
    font-size: 1.25vw;
}

h6 {
    text-align: left;
    font-weight: normal;
    font-size: 1.25vw;
}

h5.mobile {
    text-align: center;
    font-weight: normal;
    font-size: 4vw;
}

h6.mobile {
    text-align: left;
    font-weight: normal;
    font-size: 3vw;
}

a {
    color: lightgreen;
}

.modalImage {
    max-width: 100%;
}

.about_us {
    /* background-color: rgb(60 60 60); */
    background-color: black;
    margin: 0px;
    color: rgb(235 235 235);
    padding: 5%;
}

.contact {
    /* background: linear-gradient(lightgrey, white); */
    background-color: black;
    margin: 0px;
    color: black;
    padding: 5%;
}

.history {
    background-color: black;
    margin: 0px;
    color: white;
    padding: 5%;
}

.headerSizedIcon {
    transform: scale(2);
}

.leadershipTitle {
    padding-top: 3%;
    color: white;
    font-weight: normal; 
}

.simeone-button,
.trenk-button,
.ingram-button,
.badami-button,
.williams-button,
.rassias-button,
.bergeron-button,
.gallagher-button {
    /* background-color: rgb(60 60 60); */
    background: black;
    display: flex;
    justify-content: center;
    color: white;
}

.simeone-button:hover,
.trenk-button:hover,
.ingram-button:hover,
.badami-button:hover,
.williams-button:hover,
.rassias-button:hover,
.bergeron-button:hover,
.gallagher-button:hover {
    background: black;
    /* background-color: rgb(60 60 60); */
}

.memberButtonText {
    color: white;
}

.btn:focus {
    box-shadow: none;
}

.btn {
    color: white;
}

.btn:hover {
    color: lightgreen;
}

.imageCol {
    width: 25%;
    height: fit-content;
}