.pastEventColumn {
    background-color: #e7dec7;
}
.eventDetailTitle,
.eventDetailSubtitle,
.eventDetailAttendees {
    justify-content: center;
    font-weight: bold;
}

.eventDetailTitle {
    font-size: 3vw;
}

.eventDetailSubtitle,
.eventDetailAttendees {
    font-size: 2vw;
}

.eventDetailAttendees {
    font-weight: bold;
    font-size: 6vw;
}

.classYearTitle {
    font-size: 2vw;
    color: black;
}