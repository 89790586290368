.gameBreakdowns {
    width: 96%;
    padding: 25px;
    margin-right: 5px;
    margin-bottom: 15px;

    background: lightgray;
    border: lightgray;
    border-radius: 20px;
    display: inline-block;
    box-sizing: border-box; 
}


.attendanceCharts {
    width: 96%;
}

.barChart {
    margin-right: 5px;
    width: 96%;
}

.pieChart {
    margin-left: 5px;
    width: 50%;
}

.nav-link:hover {
    background-color: grey;
    border-radius: 25px;
}

.nav-link.active {
    border-radius: 25px;
}