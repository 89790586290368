body{
    overflow-x: hidden;
}

#theTop {
    height: 100vh;
}

.headerText {
    font-size: 8vw;
    color: white; 
    margin-left: auto;
    margin-right: auto;
}

.paragraphText {
    font-size: 3.5vw;
    color: white; 
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 80%;
    overflow-wrap: break-word;
}

.mobileScreenshot {
    /* max-width: calc(60vh / 2); */
    margin-left: auto;
    margin-right: auto;
    /* max-height: 100vh; */
    height: 60vh;
    margin-top: 5vh;
}

.mobilePhoto {
    display: block;
    margin-left: auto;
    margin-right: auto; 
    max-width: 80%;
    max-height: 60vh;
    margin-top: calc((1500px - 175vw) / 10)
}

.mobile-scroll-section {
    scroll-snap-align: start; /* Ensures each section snaps to the start of the viewport */
    scroll-snap-stop: always;
    height: 100vh;
    margin-bottom: 10vh;
}

.mobileRow {
    align-content: center;
    text-align: center;
    padding-top: 5vh;
}

.mobileButton {
    transition: all .2s ease-in;

    color: black;

    outline: 0;
    border: 0;
    box-shadow: none;
    font-size: 3.5vw;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 15px;
    width: 35%;

    white-space: nowrap;
}

.mobileButton#mobileButt1 {
    border-color: lightgreen;
    background-color: lightgreen;
    margin-right: 1%;
}

.mobileButton#mobileButt2 {
    border-color: white;
    background-color: white;
    margin-left: 1%;

}

.mobileButton:focus {
    outline: 0;
    border: 0;
}

