.createEvent {
    width: 100%;
    padding: 10%;
    padding-top: 0;

    border-radius: 0 0 5px 5px;
    display: inline-block;
    box-sizing: border-box;
}

.viewEvent {
    width: 100%;
    padding: 14%; /* I am a fucking genius */

    border-radius: 5px;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
}

input[type=checkbox] {
    margin: 0;
    line-height: normal;
    width: 20px;
    accent-color: green;
    height: 20px;
    border-radius: 3px;
}

.accordionEditButton,
.accordionDeleteButton {
    color: black;
    font-weight: bold;
    border: none;
    background-color: inherit;
}

.accordionEditButton:focus,
.accordionDeleteButton:focus,
.accordionEditButton:hover,
.accordionDeleteButton:hover,
.accordionEditButton:focus-visible,
.accordionDeleteButton:focus-visible {
    color: white;
    background-color: black;
}

button.accordion-button:focus {
    outline: none;
}

button.accordion-button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 1.25rem;
    border: 2px solid black;

    text-align: center;

    border-radius: 5px;

    background-color: white;
}

button.accordion-button:hover {
    background-color: #C7E7DE;
}

.accordionButtonGroup {
    display: flex;
    justify-content: right;
}

div.accordion-body {
    color: black;
    border: 2px solid black;
    background-color: #C7E7DE;
    border-radius: 5px;
    margin-bottom: 10px;
}

.photoForm {
    padding: 0;
    background-color: inherit;
    color: lightgray;
    display: block;
}

input::file-selector-button {
    height: 35px;
    font-weight: bold;
    color: black;
    border: thin solid grey;
    border-radius: 3px;
    width: 100%;
    content: "Choose a Cover Photo"
}

.photoForm:focus {
    background-color: inherit;
    outline: none;
}

.photoForm:after {
    color: green;
}
