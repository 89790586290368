/*.eventManagerSwitchFormButton:hover span.swap { !* When hovering, display the swap text *!*/
/*    display: initial;*/
/*}*/

/*.eventManagerSwitchFormButton span.swap { !* When not hovering, don't display the swap text *!*/
/*    display: none*/
/*}*/

/*.eventManagerSwitchFormButton:hover span.title { !* When hovering, don't display the title text *!*/
/*    display: none;*/
/*}*/

.eventManagerSwitchFormButton span.deactive {
    color: black;
}

.eventManagerSwitchFormButton span.active {
    color: black;
}


.eventManagerSwitchFormButton {
    background-color: lightgray;
    border-radius: 20px 20px 0 0;
    border: none;
    color: black;
    height: auto;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.eventManagerSwitchFormButton.btn-primary:hover span.deactive{
    color: white
}

.eventManagerSwitchFormButton.btn-primary:active,
.eventManagerSwitchFormButton.btn-primary:focus {
    outline: 0
}

.eventManagerSwitchFormButton#active {
    width: 50%;
    font-size: 2rem;
    min-height: 75px;
    background-color: white;
    border: 2px solid black;
}

.eventManagerSwitchFormButton#inactive {
    width: 50%;
    font-size: 2rem;
    min-height: 75px;
    background-color: lightgrey;
}

