body {
    background-color: black;
    overflow: auto;
}

#container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll; /* Enables vertical scrolling */
  height: 100vh; /* Sets container height to viewport height */
  scroll-behavior: smooth; /* Smooth scrolling effect */
  scroll-padding-top: 12vh;
  margin: 0;
  max-height: -webkit-fill-available;
  max-width: -webkit-fill-available;
}

.scroll-section {
  scroll-snap-align: start; /* Ensures each section snaps to the start of the viewport */
  scroll-snap-stop: always;
  height: 88vh;
}

.desktopScreenshot {
  max-height: 80vh;
  max-width: 40vw;
}

/* Like top gun but a butt. Heh heh. Butt.*/


#butt1 {
  border-color: lightgreen;
  background-color: lightgreen;
  margin-left: 2%;
  margin-right: 1%;
}

#butt2 {
  border-color: white;
  background-color: white;
  margin-left: 1%;
  margin-right: auto;
}

#butt1,
#butt2 {
  transition: all .2s ease-in;
  opacity: 0;
  animation: opacity_ani 3.5s ease forwards;

  outline: none;
  box-shadow: none;
  font-size: 2vw;
  display: block;
  border-radius: 15px;
  height: 4vw;
  padding: 0;
  width: 35%;
  white-space: nowrap;
}

#fadeIn {
  opacity: 0;
  animation: opacity_ani 3.5s ease forwards;
}

/* This is like a butt task force and they all have to hover before their mission*/


#butt1:hover,
#butt2:hover {
    background-color: grey;
    border-color: grey;
}



@keyframes opacity_ani { 
  from {
    opacity: 0
  } to {
    opacity: 100;
  }
}

#arrowbutton {
    width: 100%;
    background-color: white;
    border: 0;
    padding: 0;
    margin-top: 6vh;
    height: 15vh;
    border: 20px black;
    
    background: radial-gradient(green, black, black);
    background-size: 200% 200%;
    opacity: 0;

    /* -webkit-animation: Animation 5s ease infinite;
    -moz-animation: Animation 7s ease infinite; */
    animation: gradient_ani 10s ease infinite, opacity_ani 5s ease forwards;
    animation-delay: 5s, 5s;
}

#arrowbutton:focus{
  outline: 0;
}

/* @-webkit-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@-moz-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%} */
/* } */
/* @keyframes gradient_ani { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position: 0% 50%;}
} */



/* 
*****************************************************************
**************************SCROLL THING***************************
*****************************************************************
*/
#animated-text-strip{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    overflow: hidden;
    margin-bottom: -50px;
  }
  #animated-text-strip .marquee {
    white-space: nowrap;
    animation: marquee 200s linear infinite;
    max-width: none;
  }
  
  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }

  #animated-text-strip .reversemarquee {
    white-space: nowrap;
    animation: reversemarquee 200s linear infinite;
    max-width: none;
  }
  
  @keyframes reversemarquee {
    0% {
      transform: translate(-100%, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  
  /* Styles for the sake of the demonstration */
  #animated-text-strip{
    background:black;
    padding: 1rem 0;
  }
  .marquee {
    /* font-family: 'Open Sans', sans-serif; */
    font-size: 2rem;
    font-weight: 900;
    text-transform: uppercase;
    color: lightgreen;
  }

  .reversemarquee {
    /* font-family: 'Open Sans', sans-serif; */
    font-size: 2rem;
    font-weight: 900;
    text-transform: uppercase;
    color: lightgreen;
  }