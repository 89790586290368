.formComponents-eventFormSubmitButton {
    background-color: white;
    font-size: 1.25rem;
    margin-right: 10px;
    color: black;
}

.formComponents-mobileEventFormSubmitButton {
    background-color: white;
    font-size: .75rem;
    /* padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
    padding-bottom: 5%; */
    white-space: nowrap;
    color: black;
}

.formComponents-eventFormSubmitButton#submit:hover,
.formComponents-eventFormSubmitButton#submit:focus,
.formComponents-mobileEventFormSubmitButton#submit:hover,
.formComponents-mobileEventFormSubmitButton#submit:focus {
    background-color: #73b564;
    color: white
}

.formComponents-eventFormSubmitButton#clear:hover,
.formComponents-eventFormSubmitButton#clear:focus {
    background-color: red;
    color: white
}

.formComponents-eventFormSubmitButton#edit:hover,
.formComponents-eventFormSubmitButton#edit:focus {
    background-color: olive;
    color: white
}

/* MOBILE DOWN ARROW */
#app .arrow:first-child {
opacity: 0.4;
}
/* #app .arrow:nth-child(2) {
opacity: 0.7;
} */
.arrow {
padding-top: 10px;
margin-left: auto;
margin-right: auto;
}
.arrow:before {
content: "";
width: 25px;
height: 10px;
margin-right: -2px;
border-radius: 5px;
background: darkgreen;
display: inline-block;
transform: skewY(30deg);
}
.arrow:after {
content: "";
width: 25px;
height: 10px;
margin-left: -2px;
border-radius: 5px;
background: darkgreen;
display: inline-block;
transform: skewY(-30deg);
}
.arrowSliding {
animation: slide 3s linear infinite;
}
.delay1 {
animation-delay: 0s;
}
.delay2 {
animation-delay: 1s;
}
.delay3 {
animation-delay: 2s;
}

/* @keyframes slide {
0% {
    opacity: 1;
}
25% {
    opacity: 0;
}
55% {
    opacity: 0;
}
100% {
    opacity: 1;
}
} */

@keyframes slide {
    0% {
        opacity: .75;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: .75;
    }
}

#topbutt {
    border-color: lightgreen;
    background-color: lightgreen;
    transition: all .2s ease-in;
    outline: none;
    box-shadow: none;
    border-radius: 50px;
    white-space: nowrap;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 25px;
    margin-bottom: 7px;
}

#topbutt:hover {
    background-color: grey;
    border-color: grey;
}