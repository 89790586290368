.sendNotification {
    padding: 5%;
    background: black;
    border-radius: 5px;
    display: inline-block;
    box-sizing: border-box;
    margin-bottom: 9vh;
    margin-top: 9vh;
    color: white
}

.formLabel {
    font-size: 20px;
}
