/* src/components/Header/Header.css */
#header {
    background-color: black;
    color: white;
    text-align: left;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
}

#nostickheader {
    background-color: black;
    color: white;
    text-align: left;
    width: 100%;
    padding-bottom: 2vh;
}

nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: right;
}

nav li {
    margin: 0 15px;
    cursor: pointer;
}

Link {
    text-decoration: inherit;
    text-decoration-color: inherit;
}

.headerLogo {
   display: inline;
}

.headerImg {
    height: 5vw;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-right: auto;
}

.correctSizedRow {
    max-width: 100%;
    vertical-align: bottom;
}

span {
    /*display: inline-block;*/
    /*vertical-align: middle;*/
    /*line-height: normal;*/
    justify-content: center;
}

.headerLink {
    color: white;
    text-decoration: none;
    position: relative;
    padding-bottom: 4px; /* The distance between text and underline */
}

.headerLink::before {
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(90deg, white, white); /* underline color */
    background-size: 0 2px; /* vertical size of underline */
    background-repeat: no-repeat;
    background-position: left bottom; /* start position of underline */
    transition: background-size .3s ease-in; /* duration and timing style of animation */
}

.headerLink:hover::before {
    background-size: 100% 2px;
}