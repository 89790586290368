.sampleNotification {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 10px 20px;
    width: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: 26px;
    margin-top: 190px;
    min-height: 50px;
    text-align: left;
    position: absolute;
    overflow-wrap: break-word;
}

.sampleNotificationText {
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #495057;
}

.sampleNotificationText#title {
    font-weight: bold;
}

.sampleNotification {
    line-height: 20px;
}

.notification-icon {
    height: 24px;
    margin-right: 10px;
    aspect-ratio: initial;
}

.sampleIphone {
    height: 350px;
    /*aspect-ratio: 3/5;*/
    position: absolute;
    top: 10px;
    left: 0;
}

.iphone {
    position: relative;
    margin-top: 11vh;
}